import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import imagee from "../images/servicesSoftware/BannerSoftwareFactory.png"
import TextBannerSoftware from '../components/servicesSoftware/TextBammerSoftware'
import WeBuildSoftware from '../components/servicesSoftware/WeBuildSoftware'
import CustomersSF from '../components/servicesSoftware/CustomersSF'
import BannerBtnRequestQ from '../components/servicesSoftware/BannerBtnRequestQ'
import KeyBenefits from '../components/servicesSoftware/KeyBenefits'
import PopularTechnologies from '../components/servicesSoftware/PopularTechnologies'
import OurProjects from '../components/servicesSoftware/OurProjects'
import BannerServicesSoftware from '../components/servicesSoftware/BannerServicesSoftware'
import { withNamespaces } from "react-i18next";

function servicesSoftware() {
  return (
    <Layout>
        <SEO title="Services Software Factory | Exsis Digital Angels" description={"Nearshore Agile Teams | Specialists in Tech Talent"} />
        <BannerServicesSoftware image={imagee} TextComponent={TextBannerSoftware} />
        <KeyBenefits/>
        <WeBuildSoftware />
        <CustomersSF />
        <OurProjects />
        <BannerBtnRequestQ/>
        <PopularTechnologies/>
    </Layout>
  )
}

export default withNamespaces()(servicesSoftware)