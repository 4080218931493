import React, { useContext } from "react"
import { LoadingContext } from "../context/LoadingContext"
import { Link } from "gatsby"
import i18n from "i18next"
import Agrosis from "../../images/servicesSoftware/projects/AgrosisImg.jpg";
import AgrosisL from "../../images/servicesSoftware/projects/LogoAgrosisImg.png";
import Apsoft from "../../images/servicesSoftware/projects/ApsoftImg.jpg";
import ApsoftL from "../../images/servicesSoftware/projects/LogoApsoftImg.png";
import Iddea from "../../images/servicesSoftware/projects/IddeaImg.jpg" 
import IddeaL from "../../images/servicesSoftware/projects/LogoIddeaImg.png";
import Kab from "../../images/servicesSoftware/projects/KabImg.jpg" 
import KabL from "../../images/servicesSoftware/projects/LogoKabImg.png";

function OurProjects() {
  const { windowWidth } = useContext(LoadingContext)

  const arrayProjects = [
    {
        img: Agrosis,
        logo: AgrosisL,
        title: i18n.t("pageSoftwareFactory.projects.agrosis.title"),
        name: i18n.t("pageSoftwareFactory.projects.agrosis.name"),
        description:  i18n.t("pageSoftwareFactory.projects.agrosis.description"),
    },
    {
        img: Kab,
        logo: KabL,
        title: i18n.t("pageSoftwareFactory.projects.kab.title"),
        name: i18n.t("pageSoftwareFactory.projects.kab.name"),
        description:  i18n.t("pageSoftwareFactory.projects.kab.description"),
    },
    {
        img: Iddea,
        logo: IddeaL,
        title: i18n.t("pageSoftwareFactory.projects.iddea.title"),
        name: i18n.t("pageSoftwareFactory.projects.iddea.name"),
        description:  i18n.t("pageSoftwareFactory.projects.iddea.description"),
    },
    {
        img: Apsoft,
        logo: ApsoftL,
        title: i18n.t("pageSoftwareFactory.projects.apsoft.title"),
        name: i18n.t("pageSoftwareFactory.projects.apsoft.name"),
        description:  i18n.t("pageSoftwareFactory.projects.apsoft.description"),
    }
  ]


  return (
    <section className="p-8 tablet:p-20 text-left bigdesktop:pl-32 mobile:p-2">
      <div className="flex flex-col">
        <h4 className="text-blue-100 font-avenir-black text-12">
          {i18n.t("pageSoftwareFactory.projects.subtitle")}
        </h4>
        <p className="font-bold mt-2 mb-4 font-avenir-black text-gray-600 text-48 leading-tight mb-20">
          {i18n.t("pageSoftwareFactory.projects.title")}
        </p>

        {/* Projects */}
        {windowWidth >= 1025 ? (
        <div className="flex flex-col desktop:px-32">
          {arrayProjects.map((project, index) => (
            <div
              key={index}
              className={`${
                index % 2 != 0 ? "flex-row-reverse" : "flex-row"
              } flex items-center mb-16`}
            >
              {/* Imagen grande */}
              <div className={index % 2 != 0 ? "w-1/2 ml-6" :"w-1/2 mr-6"}>
                <img src={project.img} alt={project.title} />
              </div>

              {/* Información */}
              <div className={index % 2 != 0 ? "w-1/2 p-4 pr-16" :"w-1/2 p-4 pl-16"}>
                <img src={project.logo} alt={project.title} className="mb-2 imgProjectSF" />
                <p className="titleProjectSF">{project.title}</p>
                <p className="textProjectSF">
                  <span className="nameProject">{project.name}</span> {project.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        ):(
        <div className="flex flex-col">
          {arrayProjects.map((project, index) => (
            <div key={index} className="flex flex-col mb-8">
              {/* Imagen grande */}
              <div className="mb-4">
                <img src={project.img} alt={project.title} />
              </div>

              {/* Información */}
              <div className="p-4">
                <img src={project.logo} alt={project.title} className="mb-2 imgProjectSFMobile" />
                <p className="titleProjectSF">{project.title}</p>
                <p className="textProjectSF">
                  <span className="nameProject">{project.name}</span> {project.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        )}

      </div>
    </section>
  )
}

export default OurProjects
