import React, { useContext, useRef } from 'react';
import i18n from 'i18next';
import Slider from "react-slick";


import avianca from './../../images/home/Customers/image146.jpg';
import dafiti from './../../images/home/Customers/image145.jpg';
import bbva from './../../images/home/Customers/image141.jpg';
import chub from './../../images/home/Customers/image140.jpg';
import colpatria from './../../images/home/Customers/image142.jpg';
import uala from './../../images/home/Customers/image139.jpg';
import { LoadingContext } from '../context/LoadingContext';

const CustomersSF = () => {
	const { windowWidth } = useContext(LoadingContext);
    const carouselRef = useRef(null)
    const imagesArr = [
        {
            image: avianca,
            texto: "1",
        },
        {
            image: dafiti,
            texto: "1",
        },
        {
            image: bbva,
            texto: "1",
        },
        {
            image: chub,
            texto: "1",
        },
		{
            image: colpatria,
            texto: "1",
        },
		{
            image: uala,
            texto: "1",
        },
    ];

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	  }


	return (
		<div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 bannerCertification">
              
              {windowWidth >= 1025 ?  
              <div className="flip-card-inner2  mt-24 mb-16">
              	<div className="mobile:w-full  mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 ">
					<p className="titleCompanies">{i18n.t("pageSoftwareFactory.customerSF.title")}</p>              
				</div>
                  
                  <div className="flex targetBusinesses mt-12 justifyStyle">
                    {imagesArr.map((item, i) => {
                        return (
                    <div className='contentTargetExperts_SoftwareFactory '>
                            <div className='m-auto'>
                            <img src={item.image} alt="...." className={item.texto == 2 ? 'imgCardExpertCertification': 'imgCardExpert_SoftwareFactory p-6'}/>
                            </div>

                </div>)
                 }) 
                 }
              </div>
                   
                             
              </div>:
              <div className="flip-card-inner2  mt-24 mb-24">
              	<div className="mobile:w-full  mobile:mb-4 landscape:mb-0 landscape:w-full desktop:pr-4 ">
					<p className="titleCompanies">{i18n.t("pageSoftwareFactory.customerSF.title")}</p>              
				</div>
                  
                  <div className=" mt-12 justifyStyle">
                  <Slider
                      ref={carouselRef}
                      {...settings}
                      className="rounded-sm  mx-2 py-10"
                    >
                    {imagesArr.map((item, i) => {
                        return (
                    <div className='contentTargetExpertsMobile2 '>
                            <div className='m-auto'>
                            <img src={item.image} alt="...." className={item.texto == 2 ? 'imgCardCertification2': 'imgCardCertification'}/>
                            </div>

                </div>)
                 }) 
                 }
              </Slider>
              </div>
                   
                             
              </div>
              
              }
             
            </div>
	);
};

export default CustomersSF;