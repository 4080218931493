import React, { useState, useRef, useContext } from "react"
import i18n from "i18next"
import icon1 from "../../images/servicesHunting/icons/pre-operation/icon1.png"
import icon2 from "../../images/servicesHunting/icons/pre-operation/icon2.png"
import icon3 from "../../images/servicesHunting/icons/pre-operation/icon3.png"
import leftArrow from "../../images/servicesHunting/icons/leftArrow.png"
import rigthArrow from "../../images/servicesHunting/icons/rigthArrow.png"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { LoadingContext } from "../context/LoadingContext"

function KeyBenefits() {
  const icons = [icon1, icon2, icon3]
  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef(null);
  const { windowWidth } = useContext(LoadingContext);


  const itemBenefits = [
    {
        title: i18n.t("pageSoftwareFactory.keyBenefits.b1")
    },
    {
        title: i18n.t("pageSoftwareFactory.keyBenefits.b2")
    },
    {
        title: i18n.t("pageSoftwareFactory.keyBenefits.b3")
    },
    {
        title: i18n.t("pageSoftwareFactory.keyBenefits.b4")
    },
    {
        title: i18n.t("pageSoftwareFactory.keyBenefits.b5")
    },
    {
        title: i18n.t("pageSoftwareFactory.keyBenefits.b6")
    },
  ]

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <section className="p-8 tablet:p-20 text-left bigdesktop:pl-32 ">
      <div className="flex flex-col">
        <h4 className="text-blue-100 font-avenir-black text-12">
          {i18n.t("pageSoftwareFactory.keyBenefits.subtitle")}
        </h4>
        <p className="font-bold mt-2 mb-4 font-avenir-black text-gray-600 text-48 leading-tight">
          {i18n.t("pageSoftwareFactory.keyBenefits.title")}
        </p>

        {windowWidth >= 1025 ? 
        <div className="hidden desktop:flex gap-5 flex-wrap cont-benefits_SF desktop:px-12 bigdesktop:gap-40 ultradesktop:px-0">
          {itemBenefits.map((item, index) => (
            <div
              key={index}
              className="flex-1 p-8 desktop:p-14 shadow-xl rounded-xs txtSF"
            >
              <div className="flex flex-col items-center gap-3  ">
                <p className="text-20 bigdesktop:text-24 font-avenir-black text-gray-600 colorBenefits_SF">
                  {item.title}
                </p>
              </div>
            </div>
          ))}
        </div>:null}

        {/** Mobile */}
        {windowWidth < 1025 ? 
        <div className="cont-benefits_SF gap-6">
            {itemBenefits.map((item, index) => (
              <div key={index} className="flex-1 p-8 desktop:p-10 shadow-xl rounded-xs txtSF">
                <div className="flex flex-col items-center gap-3  ">
                  <p className="text-20 font-avenir-black text-gray-600 colorBenefits_SF">
                    {item.title}
                  </p>
                </div>
              </div>
            ))}
        </div> : null}
      </div>
    </section>
  )
}

export default KeyBenefits
