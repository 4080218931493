import React from "react"
import imageWeBuild from "../../images/servicesSoftware/image-we-build.png"
import xElement from "../../images/servicesHunting/icons/xDoble.png"
import { Link } from "gatsby"
import i18n from "i18next"
function WeBuildSoftware() {
    
  return (
    <section className="whyusdiv flex flex-col-reverse desktop:flex desktop:flex-row desktop:gap-8 justify-center mt-12 desktop:mt-20 p-8 pl-0 desktop:px-24 bigdesktop:px-32 text-left bigdesktop:pr-0 bigdesktop:justify-around pt-16 pb-16 mobile:pl-6">
      <div className="flex flex-col desktop:w-6/12">
        <h4 className="text-blue-100 font-avenir-black text-12">
          {i18n.t("pageSoftwareFactory.WeBuild.subtile")}
        </h4>
        <p className="font-bold mt-2 font-avenir-black text-gray-600 text-40 desktop:text-48 leading-tight">
          {i18n.t("pageSoftwareFactory.WeBuild.title")}
        </p>
        <p className="text-gray-500 font-avenir-light text-20 pt-10">
          {i18n.t("pageSoftwareFactory.WeBuild.description1")}
          <br />
          <br />
          {i18n.t("pageSoftwareFactory.WeBuild.description2")}
        </p>
        {/* missing route */}
        <span className="pt-10">
          <Link to="/contact-us">
            <button className="bg-blue-100 w-full desktop:w-fit text-white text-20 font-sofiaPro-bold rounded-lg px-10 py-2">
              {i18n.t("pageSoftwareFactory.WeBuild.button")}
            </button>
          </Link>
        </span>
      </div>

      <div className="desktop:w-1/2 ultradesktop:flex flex-col ultradesktop:items-center">
        <img
          src={imageWeBuild}
          className="ultradesktop:w-10/12 bigdesktop:w-4/5 pt-10"
          alt="whyus"
        />
        <div className="flex w-full justify-center">
          <img className="w-1/3 desktop:w-fit" src={xElement} alt="xs" />
        </div>
      </div>
    </section>
  )
}

export default WeBuildSoftware
