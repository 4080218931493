import React, { useState, useEffect, useContext } from 'react';
import { LoadingContext } from '../../components/context/LoadingContext';
import i18n from "i18next"
import angular from "./../../images/home/Experts/Angular.png"
import css from "./../../images/home/Experts/Css.png"
import html from "./../../images/home/Experts/Html.png"
import ionic from "./../../images/home/Experts/Ionic.png"
import js from "./../../images/home/Experts/Javascript.png"
import kotlin from "./../../images/home/Experts/Kotlin.png"
import mysql from "./../../images/home/Experts/Mysql.png"
import oracle from "./../../images/home/Experts/Oracle.png"
import postgre from "./../../images/home/Experts/Postgre.png"
import reactNative from "./../../images/home/Experts/React native.png"
import reactRed from "./../../images/home/Experts/React redux.png"
import xamarin from "./../../images/home/Experts/Xamarin.png"

const PopularTechnologies = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

    const elementsSlider = [
      {
        image: js,
        id: "1",
      },
      {
        image: html,
        id: "1",
      },
      {
        image: xamarin,      
        id: "1",
      },
      {
        image: reactNative,     
        id: "1",
      },
      {
        image: angular,     
        id: "1",
      },
      {
        image: ionic,     
        id: "2",
      },
      {
        image: reactRed,     
        id: "1",
      },
      {
        image: mysql,     
        id: "1",
      },
      {
        image: oracle,     
        id: "2",
      },
      {
        image: css,     
        id: "1",
      },
      {
        image: postgre,     
        id: "1",
      },
      {
        image: kotlin,     
        id: "2",
      }
  ]

  return (
    <div className="mobile:w-full desktop:pl-16 desktop:pr-4 bigdesktop:px-32 mobile:pl-4 mobile:pr-4 flip-card pyWork2">

      {windowWidth >= 1025 ? (
        <>
          <div className="flex contentApply mr-24">
            <div className="countTextCountMobile widthLogosServices">
              <p className="textAboutUs">
                {i18n.t("pageExceptionProducts.popularTechnologies.subtitle")}
              </p>
              <p className="titleOurServices">
                {i18n.t("pageExceptionProducts.popularTechnologies.title")}
              </p>
            </div>
            <div className="ml-16">
              <div className="flex marginTechnologys">
                {elementsSlider.slice(0, 4).map((item, i) => {
                  return (
                    <div className="contentTargetProducts ">
                      <div className="m-auto">
                        <img
                          src={item.image}
                          alt="...."
                          className={
                            item.id == 2
                              ? "imgCardExpertCertification"
                              : "imgCardExpert"
                          }
                        />
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="flex mt-8 ml-24">
                {elementsSlider.slice(4, 8).map((item, i) => {
                  return (
                    <div className="contentTargetProducts ">
                      <div className="m-auto">
                        <img
                          src={item.image}
                          alt="...."
                          className={
                            item.id == 2
                              ? "imgCardExpertCertification"
                              : "imgCardExpert"
                          }
                        />
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="flex marginTechnologys mt-8 mb-12">
                {elementsSlider.slice(8, 13).map((item, i) => {
                  return (
                    <div className="contentTargetProducts ">
                      <div className="m-auto">
                        <img
                          src={item.image}
                          alt="...."
                          className={
                            item.id == 2
                              ? "imgCardExpertCertification"
                              : "imgCardExpert"
                          }
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="">
            <div className="countTextCountMobile">
              <p className="subTitleOurServices">
                {i18n.t("pageExceptionProducts.popularTechnologies.subtitle")}
              </p>
              <p className="titleOurServices">
                {i18n.t("pageExceptionProducts.popularTechnologies.title")}
              </p>
            </div>

            <div className="mt-8">
              {elementsSlider.map((item, i) => {
                return (
                  <div className={`w-full h-full mb-8 flex ${i%2!==0? "justify-start": "justify-end"}`}>
                    <div className="contentTargetProductsMobile flex justify-center items-center">
                      <img
                        src={item.image}
                        alt="...."
                        className= ""
                        // {
                        //   item.id == 2
                        //     ? "imgCardExpertCertificationMobile"
                        //     : "imgCardExpertResponsive"
                        // }
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default PopularTechnologies;