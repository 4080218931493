import React from "react"
import i18n from "i18next"

function TextBannerSoftware() {
  return (
    <div className="text-left  pl-5 tablet:pl-20 bigdesktop:pl-24 text-white mb-32 tablet:mb-0">
      {i18n.language === "en" ? (
        <>
        <p className="text-20 bigdesktop:text-24 font-avenir-heavy">
          E<span className="text-blue-100">x</span>pansion Services
        </p>
        <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
            Software<span className="text-blue-100"></span>
         <br />
         <span className="text-relativo">Factory</span>
        </p>
        </>
      ) : (
        <>
        <p className="text-20 bigdesktop:text-24 font-avenir-heavy">
          Nuestros servicios
        </p>
        <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
         Fábrica de<span className="text-blue-100"></span>
         <br />
         <span className="text-relativo">Software</span>
        </p>
        </>
      )}
      
    </div>
  )
}

export default TextBannerSoftware
